import React, { useEffect } from "react";

import Error from "../error/error";
//import state, { StateParameter } from "../../utils/state";
import Grid from "@mui/material/Unstable_Grid2";
import { useParams } from "react-router-dom";
import "./subcontractorOrdersView.css";
import { datastore } from "../../datastore";
import { useQuery, gql } from "@apollo/client";
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from 'react-i18next';
import moment from "moment";



//import FormControlLabel from "@mui/material/FormControlLabel";
import TopMenu, { TopMenuItem, viewType } from "../../components/TopMenu/TopMenu";
import Logo from "../../components/Logo/Logo";


const GET_ORDERS_NEW = gql`
  query getSubcontractorOrders($subcontractorId: ID!) {
    getSubcontractorOrders(subcontractorId: $subcontractorId) {
      id
      status
      caseNo
      address
      photographyCompanyName
      photeshotEvent
      mediaCount
      createdAt
    }
    subcontractor(id: $subcontractorId) {
      id
      name
      language
      subcontractorType
    }
  }
`;


const GET_ORDERS = gql`
  query subcontractor($id: ID!) {
    subcontractor(id: $id) {
      id
      name
      language
      subcontractorType
      orders {
        id
        status
        createdAt
        photographyCompany {
            id
            name
          }
        order {
          id
          caseNo
          estateAddress
          estateZipCode
          estateCity
          estateType
          photeshotEvent {
            id
            start
          }
          media {
            id
          }
        }
      }
    }
  }
`;

const SubcontractorOrdersView: React.FC = () => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    let root = document.documentElement;
    root.style.setProperty("--text-color", "#FFF");
    root.style.setProperty("--primary-color", "#000");
    root.style.setProperty("--secondary-color", "#FFF");
  }, []);


  if (!token) {
    //return <Error errorCode={500} />;
  }

  var decodedUrlToken = jwtDecode(token + '') as any;

  const { loading, error, data } = useQuery(GET_ORDERS_NEW, {
    variables: {
      subcontractorId: decodedUrlToken.id
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (data && data.subcontractor) {
      i18n.changeLanguage(data.subcontractor.language);
    }
  }, [data]);

  if (loading) {
    return <div></div>;
  }

  if (error) {
   // return <Error errorCode={500} />;
  }

  if (!data) {
   // return (<Error errorCode={500} />);
  }

  const dateComparator = (v1: string, v2: string) => {
    if (v1 === '') {
      return 1;
    }
    if (v2 === '') {
      return -1;
    }
    const format = 'DD-MM-YYYY';
    const dateA = moment(v1, format).toDate() as Date;
    const dateB = moment(v2, format).toDate() as Date;
    return (dateB as any) - (dateA as any);
  };

  const columns: GridColDef[] = [
    { field: 'caseNo', headerName: t('OrderListHeaderCaseNo'), width: 120, filterable: false },
    { field: 'estateAddress', headerName: t('OrderListHeaderAddress'), minWidth: 400, flex: 1 },
    { field: 'photographyCompanyName', headerName: t('OrderListHeaderCustomer'), flex: 1 },
    { field: 'status', headerName: t('OrderListHeaderStatus'), minWidth: 150, align: 'left' },
    { field: 'photeshotDate', headerName: t('OrderListHeaderPhoteshotDate'), minWidth: 120, align: 'center', sortComparator: dateComparator },
    { field: 'images', headerName: t('OrderListHeaderImageCount'), minWidth: 120, align: 'center' },
    { field: 'createdAt', headerName: t('OrderListHeaderCreated'), width: 120, align: 'left' , sortComparator: dateComparator},
  ];

  const translateStatus = (status: string) => {
    switch (status) {
      case 'SENT':
        return t('subcontractor_status_sent');
      case 'DOWNLOADED':
        return t('subcontractor_status_downloaded');
      case 'COMPLETED':
        return t('subcontractor_status_completed'); //'Ordren er leveret'
      case 'REJECTED':
        return t('subcontractor_status_rejected'); // 'Ordren er afvist'
      case 'CANCELED':
        return t('subcontractor_status_canceled'); // 'Ordren er annulleret'
      default:
        break;
    }
  }

  const rows = data.getSubcontractorOrders.map((order: any) => {
    return {
      id: order.id,
      caseNo: order.caseNo,
      estateAddress: order.address,
      status: translateStatus(order.status),
      images: order.mediaCount,
      photographyCompanyName: order.photographyCompanyName,
      createdAt: moment(order.createdAt).format("DD-MM-YYYY"),
      photeshotDate:  order.photeshotEvent ? moment(order.photeshotEvent).format("DD-MM-YYYY") : ''
    }
  });

  //remove items with same id
  const uniqueRows = Array.from(new Set(rows.map((a:any) => a.id)))
    .map(id => {
      return rows.find((a:any) => a.id === id)
    });

/*
const rows = data.subcontractor.orders.map((order: any) => {
    return {
      id: order.id,
      caseNo: order.order.caseNo,
      estateAddress: order.order.estateAddress + ', ' + order.order.estateZipCode + ' ' + order.order.estateCity,
      status: translateStatus(order.status),
      images: order.order.media.length,
      photographyCompanyName: order.photographyCompany.name,
      createdAt: order.createdAt.split('T')[0],
      photeshotDate: order.order.photeshotEvent ? order.order.photeshotEvent.start.split('T')[0] : ''
    }
  });


*/

  const handleRowClicked = (row: any) => {
    if (data.subcontractor.subcontractorType === 'MEDIA_PROCESSOR') {
      navigate('/mediaprocessor/' + row.id + '/' + token);
    }

    if (data.subcontractor.subcontractorType === 'AUTHER') {
      navigate('/auther/' + row.id + '/' + token);
    }
  }

 

  return (
    <div className="orderList MainArea">
      <header className="TopAreaMain">
        <Logo />
        {data.subcontractor.subcontractorType === 'MEDIA_PROCESSOR' && (
          <TopMenu selectedItem={TopMenuItem.MediaProcessorList} view={viewType.MediaProcessor} customerName={null} />
        )}
        {data.subcontractor.subcontractorType === 'AUTHER' && (
          <TopMenu selectedItem={TopMenuItem.AuthorOrderList} view={viewType.Auther} customerName={null} />
        )}
      </header>
      <Grid container spacing={0}>
        <Grid xs={12}>
          <div className="contentContainer">
            <div className="Heading">{t('OrderListHeading')}</div>
            <Grid container spacing={0}>
              <Grid xs={12} style={{ height: '100vh', width: '100%', borderWidth: '0px' }}>
                <DataGrid
                  style={{ borderWidth: '0px' }}
                  onRowClick={handleRowClicked}
                  rows={uniqueRows}
                  columns={columns}
                  initialState={{
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterValues: [''],
                      },
                    },
                  }}
                  autoHeight={true}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                  sx={{
                    // disable cell selection style
                    '.MuiDataGrid-cell:focus': {
                      outline: 'none'
                    },
                    // pointer cursor on ALL rows
                    '& .MuiDataGrid-row:hover': {
                      cursor: 'pointer'
                    }
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SubcontractorOrdersView;
