import * as React from "react";

import { useQuery, gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import Error from "../error/error";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import Counter from "yet-another-react-lightbox/plugins/counter";
import "yet-another-react-lightbox/plugins/counter.css";

import Video from "yet-another-react-lightbox/plugins/video";
import "yet-another-react-lightbox/styles.css";


const GET_ORDER = gql`
  query getOrder($id: ID!) {
    getOrder(id: $id) {
      id
      caseNo
      estateAddress
      estateZipCode
      estateCity
      media {
        id
        thumpnailUrl
        orgUrl
        mimeType
      }
      customer {
        name
      }
    }
  }
`;

const PublicMediaView: React.FC = () => {
  const thumbnailsRef = React.useRef(null);

  const { orderId } = useParams();
  const [open, setOpen] = React.useState(true);
  
  const { loading, error, data } = useQuery(GET_ORDER, {
    variables: {
      id: orderId
    },
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return <div></div>;
  }

  if (error) {
    return <Error errorCode={500} />;
  }

  if (!data) {
    return (<Error errorCode={500} />);
  }

  //map media to slides
  const slides = data.getOrder.media.map((media: any) => {
    if (media.mimeType === "video/mp4") {
      return { poster: media.thumpnailUrl, width: media.widthInPx, height: media.heightInPx, type: "video", sources: [{ src: media.orgUrl, type: "video/mp4" }] };
    } else {
      return { src: media.thumpnailUrl, SrcSet: [{ src: media.thumpnailUrl }], type: "image" };
    }
  });

  return (
    <>
    <div style={{display:'none',height:'40px', width:'100%',background:'#008737',position: 'fixed', 'zIndex': '100000' }}>

    </div>
      <Lightbox
        styles={{ toolbar: { display: "none" }, thumbnailsContainer: { backgroundColor: "#353535"}}}
        plugins={[Thumbnails, Counter, Video]}
        counter={{ container: { style: { top: "unset", bottom: 0 } } }}
        thumbnails={{ ref: thumbnailsRef }}
        open={open}
        close={() => setOpen(false)}
       
        slides={slides}
        index={0}
      />
    </>
  );
};

export default PublicMediaView;
