import React from "react";

import Error from "../error/error";
//import state, { StateParameter } from "../../utils/state";
import Grid from "@mui/material/Unstable_Grid2";
import "./settings.css";
import { datastore } from "../../datastore";
import { useMutation, gql, useQuery } from "@apollo/client";

//import FormControlLabel from "@mui/material/FormControlLabel";
import TopMenu, { TopMenuItem, viewType } from "../../components/TopMenu/TopMenu";
import Logo from "../../components/Logo/Logo";
import { Card, CardContent, CardHeader, Checkbox, FormControlLabel, FormGroup, Paper, Snackbar, TextField } from "@mui/material";


const UPDATE_CUSTOMER = gql`
  mutation updateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      id
    }
  }
`;

const GET_CUSTOMER = gql`
  query getCustomerById($customerId: ID!) {
    getCustomerById(customerId: $customerId) {
      id
      name
      address
      zipCode
      city
      cvr
      email
      phoneNo
      contactPersonName
      allowOrderConfirmedNotification
      allowOrderMessageNotification
      allowOrderDeliveredNotification
    }
  }
`;

const Settings: React.FC = () => {
  const [openSavedInfo, setOpenSavedInfo] = React.useState(false);
  const [openErrorInfo, setOpenErrorInfo] = React.useState(false);
  
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER);

  console.log('datastore.data.orderData.customerId:', datastore.data.orderData.customerId, 'datastore.data.customerId:', datastore.data.customerId)
  const { loading, error, data } = useQuery(GET_CUSTOMER, {
    variables: {
      customerId: datastore.data.orderData.customerId ? datastore.data.orderData.customerId : localStorage.getItem("customerId")
    },
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return <div></div>;
  }

  if (error) {
    return <Error errorCode={500} />;
  }

  if (!data) {
    return (<Error errorCode={500} />);
  }

  const handleDataChange = (event: any) => {
    let inputData = {} as any
    inputData.id = data.getCustomerById.id;

    const field = event.target.name;
    const value = event.target.value;

    if (field === 'allowOrderConfirmedNotification' || field === 'allowOrderMessageNotification' || field === 'allowOrderDeliveredNotification') {

      inputData[field] = event.target.checked;
    } else {

      if (field === 'zipCodeAndCity') {
        inputData['zipCode'] = value.match(/\d+/g)[0];
        inputData['city'] = value.match(/[a-zA-Z]+/g)[0];
      } else {
        inputData[field] = value;
      }
    }

    updateCustomer({ variables: { input: inputData } }).then(() => {
      setOpenSavedInfo(true)
    }).catch((error) => {
      console.log('Error updating customer:', error);
      setOpenErrorInfo(true);
    });
  }

  const handleErrorClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenErrorInfo(false);
  };

  const handleInfoClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSavedInfo(false);
  };

  const handleSignOut = () => {
    localStorage.clear();
    datastore.data.customerId = "";
    datastore.data.shopNo ="";
    datastore.data.providerCustomerName = "";
    datastore.data.customerId = "";
    //sent the user to the login page
    window.location.href = "/login";
  } 

  return (
    <div className="settings MainArea">
      <header className="TopAreaMain">
        <Logo />
        <TopMenu selectedItem={TopMenuItem.Settings} view={viewType.EstateAgent} customerName={datastore.data.customer.name} />
      </header>

      <Grid container spacing={0}>
        <Grid xs={12}>
          <div className="contentContainer">
            <div className="Heading">Indstillinger</div>
            <Grid container spacing={2}>
              <Grid xs={6}>
                <Paper elevation={3} style={{ height: '100%' }} >
                  <Card style={{ height: '100%' }}>
                    <CardHeader title={'Profil'} action={
                      <div className="signOutButton" onClick={handleSignOut}>
                        Log ud
                      </div>
                    }  />
                    <CardContent>
                      <div>
                       
                        <div className="item">
                          <div className="itemLabel">Navn:</div>
                          <div className="itemData"><TextField
                            fullWidth
                            size="small"
                            name="name"
                            variant="standard"
                            defaultValue={data.getCustomerById.name} onBlur={handleDataChange} />
                          </div>
                        </div>
                        <div className="item">
                          <div className="itemLabel">Kontakt person:</div>
                          <div className="itemData"><TextField
                            fullWidth
                            size="small"
                            name="contactPersonName"
                            variant="standard"
                            defaultValue={data.getCustomerById.contactPersonName} onBlur={handleDataChange} />
                          </div>
                        </div>

                        <div className="item">
                          <div className="itemLabel">Vejnavn og nr.:</div>
                          <div className="itemData"><TextField
                            fullWidth
                            size="small"
                            name="address"
                            variant="standard"
                            defaultValue={data.getCustomerById.address} onBlur={handleDataChange} />
                          </div>
                        </div>

                        <div className="item">
                          <div className="itemLabel">Post nr. og by:</div>
                          <div className="itemData"><TextField
                            fullWidth
                            size="small"
                            name="zipCodeAndCity"
                            variant="standard"
                            defaultValue={data.getCustomerById.zipCode + ' ' + data.getCustomerById.city} onBlur={handleDataChange} />
                          </div>
                        </div>

                        <div className="item">
                          <div className="itemLabel">cvr:</div>
                          <div className="itemData"><TextField
                            fullWidth
                            size="small"
                            name="cvr"
                            variant="standard"
                            defaultValue={data.getCustomerById.cvr} onBlur={handleDataChange} />
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>
              <Grid xs={6}>
                <Paper elevation={3} style={{ height: '100%' }} >
                  <Card style={{ height: '100%' }}>
                    <CardHeader title={'Notifikationer'} />
                    <CardContent>
                      <div>
                        <FormGroup>
                        {datastore.data.photographyCompany.showAllowOrderConfirmedNotificationButton && (
                          <FormControlLabel control={<Checkbox onChange={handleDataChange} name="allowOrderConfirmedNotification" defaultChecked={data.getCustomerById.allowOrderConfirmedNotification} />} label="Modtag ordrebekræftelse på e-mail" />
                        )}

                        {datastore.data.photographyCompany.showAllowOrderMessageNotificationButton && (
                          <FormControlLabel control={<Checkbox onChange={handleDataChange} name="allowOrderMessageNotification" defaultChecked={data.getCustomerById.allowOrderMessageNotification} />} label="Modtag e-mail notifikationer omkring beskeder/note ændringer." />
                        )}
                        {datastore.data.photographyCompany.showAllowOrderDeliveredNotificationButton && (
                          <FormControlLabel control={<Checkbox onChange={handleDataChange} name="allowOrderDeliveredNotification" defaultChecked={data.getCustomerById.allowOrderDeliveredNotification} />} label="Modtag e-mail notifikationer, når en ordre er leveret" />
                        )}
                        </FormGroup>
                      </div>
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Snackbar
        open={openSavedInfo}
        autoHideDuration={2000}
        message="Ændringen er blevet gemt."
        onClose={handleInfoClose}
      />

      <Snackbar
        open={openErrorInfo}
        autoHideDuration={2000}
        ContentProps={{
          sx: {
            background: "red"
          }
        }}
        message="Opdatering af profil data fejlede."
        onClose={handleErrorClose}
      />
    </div>
  );
};

export default Settings;
