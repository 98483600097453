import React, { useRef } from "react";
import "./login.css";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useMutation, gql } from "@apollo/client";
import { Navigate, useNavigate  } from "react-router-dom";
import { datastore } from "../../datastore";
import { Customer } from "./../../providers/customer";
import { jwtDecode } from "jwt-decode";

interface IProps {}

const LOGIN_GQL = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password){
      id
      token
      mwShopId
      mwSystemName
    }
  }
`;

const Login: React.FC<IProps> = () => {
 // const [showError, setShowError] = useState(false);
  const loginTextBox = useRef<HTMLInputElement | null>(null);
  const passwordTextBox = useRef<HTMLInputElement | null>(null);
  const [Login, { data, error }] = useMutation(LOGIN_GQL);
  const navigate = useNavigate();


  const handleForgotPasswordClicked = () => {
    navigate("/forgotpassword");
  };

  const handleLoginClicked = () => {
    Login({
      errorPolicy: "all",
      variables: {
        email: loginTextBox.current?.value,
        password: passwordTextBox.current?.value,
      },
    });
  };

  let showError = false;
  if (error) {
    showError = true;
  }





  //Is user allready logged in?
  const token = localStorage.getItem("id_token");
  if (token && token !== "") {
    var decodedToken = jwtDecode(token);

    var dateNow = new Date();
    if (decodedToken.exp) {
      if (decodedToken.exp * 1000 < dateNow.getTime()) {
        console.log("Token expired.");
        localStorage.clear();
      } else {
        console.log("Valid token");
        return (<Customer><Navigate to="/newOrder" replace /></Customer>);
      }
    }
  }

  if (data && data.login && data.login.token) {
    localStorage.setItem("id_token", data.login.token);
    localStorage.setItem("providerCustomerName", data.login.mwSystemName);
    localStorage.setItem("shopNo", data.login.mwShopId);
    localStorage.setItem("customerId", data.login.id);
    datastore.data.customerId = data.login.id;
    datastore.data.shopNo = data.login.mwShopId
    datastore.data.providerCustomerName = data.login.mwSystemName
    datastore.data.customerId = data.login.id
    return (<Customer><Navigate to="/newOrder" replace /></Customer>);
  } else {
    return (
      <div className="login">
        <div className="logo"></div>
        <div className="loginBackground"></div>
        <div className="loginContainer">
          <div className="loginBoxContainer">
            <div className="textContainer">
              <h1>Log ind</h1>
              {showError && <p className="warning">Din e-mailadresse eller adgangskode er forkert - prøv igen.</p>}
              <TextField fullWidth id="username" label="E-mailadresse" variant="standard" defaultValue={""} inputRef={loginTextBox} />
              <br />
              <br />
              <TextField fullWidth id="password" label="Adgangskode" variant="standard" type="password" defaultValue={""} inputRef={passwordTextBox} />
              <br />
            </div>
            <div className="buttonContainer">
              <p
                onClick={() => {
                  handleForgotPasswordClicked();
                }}
                style={{ cursor: "pointer" }}
              >
                <u>Glemt adgangskode</u>
              </p>
              <Button
                onClick={() => {
                  handleLoginClicked();
                }}
                className="loginButton"
                variant="contained"
              >
                Login
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
export default Login;
