import Mindworking from "./utils/mindworking";

export const datastore = {
  async setFromMWData(caseNo: string) {
    console.log("setFromMWData - case no:", caseNo);
    if (localStorage.getItem("providerCustomerName") && localStorage.getItem("shopNo")) {
      const MwCaseDataJson = await new Mindworking().getCaseInfo(
        localStorage.getItem("providerCustomerName"),
        localStorage.getItem("shopNo"),
        caseNo
      );

      console.log("MwCaseDataJson:", MwCaseDataJson);

      if (MwCaseDataJson && MwCaseDataJson.Case && MwCaseDataJson.Case.PropertyInfo) {
        console.log("setting new data");

        console.log("MwCaseDataJson.Case.PropertyInfo.Floor:", MwCaseDataJson.Case.PropertyInfo.Floor);
        if (MwCaseDataJson.Case.PropertyInfo.Floor && MwCaseDataJson.Case.PropertyInfo.Floor !== "" && MwCaseDataJson.Case.PropertyInfo.Floor !== "null") {
          this.data.orderData.estateAddress = MwCaseDataJson.Case.PropertyInfo.AddressRoadName + " " + MwCaseDataJson.Case.PropertyInfo.AddressHouseNumber + ", " + MwCaseDataJson.Case.PropertyInfo.Floor + " " + (MwCaseDataJson.Case.PropertyInfo.AddressDoorLocation !== null ? MwCaseDataJson.Case.PropertyInfo.AddressDoorLocation : "");
        }else{
          this.data.orderData.estateAddress = MwCaseDataJson.Case.PropertyInfo.AddressRoadName + " " + MwCaseDataJson.Case.PropertyInfo.AddressHouseNumber;
        }
        
        this.data.orderData.estateZipCode = MwCaseDataJson.Case.PropertyInfo.ZipCode;
        this.data.orderData.estateCity = MwCaseDataJson.Case.PropertyInfo.AddressCityName;
        this.data.orderData.estateType = MwCaseDataJson.Case.PropertyInfo.PropertyTypeDescription;

        if (this.data.orderData.estateType === "Lejlighed") {
          this.data.orderData.estateType = "Ejerlejlighed"
        }

        if (this.data.orderData.estateType === "Butik") {
          this.data.orderData.estateType = "Erhverv - Butik/Detail"
        }

        //this.data.orderData.estateAgentEmail = MwCaseDataJson.Case.CaseInfo.CaseBrokers.Casebroker1.BrokerEmail;
        //this.data.orderData.estateAgentPhone = MwCaseDataJson.Case.CaseInfo.CaseBrokers.Casebroker1.BrokerTelephone;
        //this.data.orderData.estateAgentName = MwCaseDataJson.Case.CaseInfo.CaseBrokers.Casebroker1.BrokerName;

        this.data.orderData.estateAgentEmail = localStorage.getItem("estateAgentEmail") ? (localStorage.getItem("estateAgentEmail") as string) : "";
        this.data.orderData.estateAgentPhone = localStorage.getItem("estateAgentPhone") ? (localStorage.getItem("estateAgentPhone") as string) : "";
        this.data.orderData.estateAgentName = localStorage.getItem("estateAgentName") ? (localStorage.getItem("estateAgentName") as string) : "";

        this.data.orderData.caseNo = MwCaseDataJson.Case.CaseInfo.CaseNo;
        this.data.orderData.shopNo = MwCaseDataJson.Case.SalesInfo.Shop.ShopID;


        if (MwCaseDataJson.Case.PropertyInfo.TotalFloors) {
          this.data.orderData.estateFloorCount = parseInt(MwCaseDataJson.Case.PropertyInfo.TotalFloors as string);
        }
        if (MwCaseDataJson.Case.PropertyInfo.RoomCount) {
          this.data.orderData.estateRoomCount = parseInt(MwCaseDataJson.Case.PropertyInfo.RoomCount as string);
        }

        if (MwCaseDataJson.Case.PropertyInfo.Business.BusinessArea) {
          this.data.orderData.estateBusinessArea = parseInt(MwCaseDataJson.Case.PropertyInfo.Business.BusinessArea as string);
        }
        if (MwCaseDataJson.Case.PropertyInfo.HouseArea) {
          this.data.orderData.estateLivingArea = parseInt(MwCaseDataJson.Case.PropertyInfo.HouseArea as string);
        }

        //this.data.orderData.sellerName = localStorage.getItem("sellerName") as string;
        //this.data.orderData.sellerPhone = localStorage.getItem("sellerPhone") as string;
        //this.data.orderData.sellerEmail = localStorage.getItem("sellerEmail") as string;
        //this.data.orderData.sellerType = localStorage.getItem("sellerType") as string;


      } else {
        this.data.orderData.estateAddress = "";
        this.data.orderData.estateZipCode = "";
        this.data.orderData.estateCity = "";
        this.data.orderData.estateType = "Villa";
        this.data.orderData.estateAgentEmail = "";
        this.data.orderData.estateAgentPhone = "";
        this.data.orderData.estateAgentName = "";
        this.data.orderData.estateFloorCount = 0;
        this.data.orderData.estateRoomCount = 0;
        this.data.orderData.estateBusinessArea = 0;
        this.data.orderData.estateLivingArea = 0;
      }
      
      return true;
    }

    return false;
  },
  setDataFromUrl() {
    const queryParameters = new URLSearchParams(window.location.search);

    if (queryParameters.get("provider")){
      this.data.dataSetFromUrl = true;
    }

    this.data.provider = queryParameters.get("provider") as string;

    this.data.providerCustomerName = localStorage.getItem("providerCustomerName") as string;
    if (queryParameters.get("customer") && (queryParameters.get("customer") as string) !== "") {
      this.data.providerCustomerName = "https://" + (queryParameters.get("customer") as string) + ".mindworking.eu";
      localStorage.setItem("providerCustomerName", this.data.providerCustomerName);
    }

    this.data.shopNo = localStorage.getItem("shopNo") as string;
    if (queryParameters.get("shopNo") && (queryParameters.get("shopNo") as string) !== "") {
      this.data.shopNo = queryParameters.get("shopNo") as string;
      localStorage.setItem("shopNo", this.data.shopNo);
    }
    //this.data.orderData.estateAgentEmail = queryParameters.get("maeglersMail") as string;

    this.data.orderData.estateAgentEmail = localStorage.getItem("estateAgentEmail")
      ? (localStorage.getItem("estateAgentEmail") as string)
      : (queryParameters.get("maeglersMail") as string);
    this.data.orderData.estateAgentPhone = localStorage.getItem("estateAgentPhone") ? (localStorage.getItem("estateAgentPhone") as string) : "";
    this.data.orderData.estateAgentName = localStorage.getItem("estateAgentName") ? (localStorage.getItem("estateAgentName") as string) : "";

    this.data.orderData.caseNo = queryParameters.get("sagsnr") as string;
    this.data.orderData.shopNo = queryParameters.get("shopNo") as string;
    this.data.orderData.estateAddress = queryParameters.get("adresse") as string;
    this.data.orderData.estateZipCode = queryParameters.get("postnr") as string;
    this.data.orderData.estateCity = queryParameters.get("by") as string;
    //this.data.orderData.estateLocationName = queryParameters.get("stednavn") as string;
    this.data.orderData.estateType = queryParameters.get("ejdtype") as string;

    if (this.data.orderData.estateType && this.data.orderData.estateType.indexOf('Villa') > -1) {
      this.data.orderData.estateType = "Villa"
    }

    if (queryParameters.get("antalPlan")) {
      this.data.orderData.estateFloorCount = parseInt(queryParameters.get("antalPlan") as string);
    }
    if (queryParameters.get("antalVaerelser")) {
      this.data.orderData.estateRoomCount = parseInt(queryParameters.get("antalVaerelser") as string);
    }

    this.data.orderData.sellerName = queryParameters.get("saelger1Navn") as string;
    if ((queryParameters.get("saelger1TlfPriv") as string) !== "") {
      this.data.orderData.sellerPhone = queryParameters.get("saelger1TlfPriv") as string;
    } else {
      if ((queryParameters.get("saelger1TlfMob") as string) !== "") {
        this.data.orderData.sellerPhone = queryParameters.get("saelger1TlfMob") as string;
      } else {
        if ((queryParameters.get("saelger1TlfArb") as string) !== "") {
          this.data.orderData.sellerPhone = queryParameters.get("saelger1TlfArb") as string;
        }
      }
    }

    this.data.orderData.sellerEmail = queryParameters.get("saelger1Mail") ? (queryParameters.get("saelger1Mail") as string) : "";
    
    if (queryParameters.get("erhvervsAreal")) {
      this.data.orderData.estateBusinessArea = parseInt(queryParameters.get("erhvervsAreal") as string);
    }
    if (queryParameters.get("boligareal")) {
      this.data.orderData.estateLivingArea = parseInt(queryParameters.get("boligareal") as string);
    }

    //Data som sendes fra MW, men ikke benyttes
    //this.data.orderData.estateAgentEmail = queryParameters.get("caseNo") as string;
    //this.data.orderData.estateAgentEmail = queryParameters.get("areal")  as string;
    //this.data.orderData.estateAgentEmail = queryParameters.get("kaelderAreal") as string;
    //this.data.orderData.estateAgentEmail = queryParameters.get("carportAreal") as string;
    //this.data.orderData.estateAgentEmail = queryParameters.get("garageAreal") as string;
    //this.data.orderData.estateAgentEmail = queryParameters.get("udehusAreal") as string;
  },

  data: {
    customerId: "",
    subcontractorId: "",
    token: "",
    dataSetFromUrl: false,
    provider: "" as string,
    providerCustomerName: "" as string,
    customer: {
      name: "",
      cvr: "",
      address: "",
      zipCode: "",
      city: "",
    },
    shopNo: "",
    orderData: {
      customerId: "",
      caseNo: "",
      shopNo: "",
      billingName: "",
      billingCvr: "",
      billingAddress: "",
      billingZipCode: "",
      billingCity: "",

      estateAgentName: "",
      estateAgentEmail: "",
      estateAgentPhone: "",

      estateAddress: "",
      estateZipCode: "",
      estateCity: "",
      estateType: "Villa",
      estateFloorCount: 1,
      estateRoomCount: 0,
      estateLivingArea: 0,
      estateBusinessArea: 0,
      sellerName: "",
      sellerPhone: "",
      sellerEmail: "",
      sellerType: "Privat",
      note: "",
      products: [] as any[],
      keyCase: "NO"
    },
    productCategories: [] as any[],
    photographyCompany: {
      id: "",
      name: "",
      address: "",
      city: "",
      zipCode: "",
      cvr: "",
      email: "",
      logoUrl: "",
      phoneNo: "",
      primaryColor: "",
      secondaryColor: "",
      textColor: "",
      buyingTermsUrl: "",
      showAllowOrderConfirmedNotificationButton: false,
      showAllowOrderMessageNotificationButton: false,
      showAllowOrderDeliveredNotificationButton: false,
    },
  },
};
