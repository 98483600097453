import React, { FC } from "react";
import './TopMenu.css'
import { useNavigate } from "react-router-dom";
import { datastore } from "../../datastore";
import { useTranslation } from 'react-i18next';

export enum TopMenuItem {
  NewOrder,
  AuthorOrder,
  AuthorOrderList,
  MediaProcessorOrder,
  MediaProcessorList,
  OrderList,
  Settings,
  None
}


export enum viewType {
  Auther,
  EstateAgent,
  MediaProcessor
}

export interface TopMenuProps {
  selectedItem: TopMenuItem
  view: viewType
  customerName: string | null
}

const TopMenu: FC<TopMenuProps> = (props) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const handleItemClicked = (item: TopMenuItem) => {
    switch (item) {
      case TopMenuItem.AuthorOrder:
        navigate('/auther/' + datastore.data.token);
        break;
      case TopMenuItem.AuthorOrderList:
        navigate('/auther/' + datastore.data.token);
        break;
      case TopMenuItem.MediaProcessorOrder:
        navigate('/mediaprocessor/' + datastore.data.token);
        break;
      case TopMenuItem.MediaProcessorList:
        navigate('/mediaprocessor/' + datastore.data.token);
        break;
      case TopMenuItem.NewOrder:
        navigate('/neworder');
        break;
      case TopMenuItem.OrderList:
        navigate('/orders');
        break;
      case TopMenuItem.Settings:
        navigate('/settings');
        break;

      default:
        break;
    }

  }

  return (

    <div className="MainMenu">
      {props.view === viewType.EstateAgent && (
        <>
          <input id="newOrder" type="radio" name="css-tabs" checked={props.selectedItem === TopMenuItem.NewOrder ? true : false} onClick={() => handleItemClicked(TopMenuItem.NewOrder)} />
          <input id="orderList" type="radio" name="css-tabs" checked={props.selectedItem === TopMenuItem.OrderList ? true : false} onClick={() => handleItemClicked(TopMenuItem.OrderList)} />
          <input id="settings" type="radio" name="css-tabs" checked={props.selectedItem === TopMenuItem.Settings ? true : false} onClick={() => handleItemClicked(TopMenuItem.Settings)} />

          <div id="tabs">
            <label id="tab1" htmlFor="newOrder">
              Ny bestilling
            </label>
            <label id="tab2" htmlFor="orderList">
              bestillinger
            </label>
            <label id="tab3" htmlFor="settings">
              Indstillinger
            </label>

          </div>
        </>
      )}
      {props.view === viewType.Auther && (
        <>
          <input id="authorOrderList" type="radio" name="css-tabs" checked={props.selectedItem === TopMenuItem.AuthorOrderList ? true : false} onClick={() => handleItemClicked(TopMenuItem.AuthorOrderList)} />

          <div id="tabs">


            <label id="tab1" htmlFor="authorOrderList">
              {t('TopMenu_Orders')}
            </label>
          </div>
        </>
      )}
      {props.view === viewType.MediaProcessor && (
        <>
          <input id="mediaProcessorList" type="radio" name="css-tabs" checked={props.selectedItem === TopMenuItem.MediaProcessorList ? true : false} onClick={() => handleItemClicked(TopMenuItem.MediaProcessorList)} />

          <div id="tabs">


            <label id="tab1" htmlFor="mediaProcessorList">
              {t('TopMenu_Orders')}
            </label>
          </div>
        </>
      )}
      {props.customerName && (
        <div className="CustomerNameContainer">
          <div className="CustomerName">
            {props.customerName}
          </div>
        </div>
      )}
    </div>
  );
};

export default TopMenu;
