import React, { FC, useEffect, useState } from "react";
import './MessageModal.css'
import { useTranslation } from 'react-i18next';

import { Card, CardContent, CardHeader, Snackbar, Input, TextField, Button, styled, Modal, Box, Typography, } from "@mui/material";
import moment from "moment";
import { gql, useMutation } from "@apollo/client";
import { datastore } from "../../datastore";
import { useParams } from "react-router-dom";

const SENT_MESSAGE = gql`
  mutation sentMessage($input: SentMessageInput!) {
    sentMessage(input: $input) {
      id
    }
  }
`;

export enum messageViewType {
    ViewMessage = "ViewMessage",
    ReplyMessage = "ReplyMessage",
    SentMessage = "SentMessage"
}

export interface MessageModalProps {
    onClose: any
    open: boolean
    view: messageViewType
    messageObject: any
    photographyCompanyId: string
}

const MessageModal: FC<MessageModalProps> = (props) => {
    const { t, i18n } = useTranslation();
    //const replySubjectRef = React.createRef();
    //const replyTextRef = React.createRef();
    const [subjectValue, setSubjectValue] = useState('');
    const [textValue, setTextValue] = useState('');

    const { subcontractorOrderId, token } = useParams();
    const [messageView, setMessageView] = React.useState(props.view);
    const [sentMessage] = useMutation(SENT_MESSAGE, {
        context: {
          headers: {
            authorization: "Bearer " + token,
          }
        }
      });

      datastore.data.token = token + '';

    useEffect(() => {
        setMessageView(props.view);
    }, [props.view]);

    const messageModalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        height: 500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleReplyMessage = () => {
        setSubjectValue('SV: ' + props.messageObject.messageSubject);
        setMessageView(messageViewType.ReplyMessage);
    }

    const handleSentMessage = () => {
        let input = {
            photographyCompanyId: props.photographyCompanyId,
            messageSubject: subjectValue,
            messageText: textValue,
            messageSender: "SUBCONTRACTOR"
        } as any;

        if (props.messageObject) {
            input.threadId = props.messageObject.threadId;
            input.parentMessageId = props.messageObject.id;
        }

        sentMessage({
            variables: {
                input 
            }
        });

        setMessageView(messageViewType.ViewMessage);
        
        props.onClose();
    }

    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={messageModalStyle}>
                
                {messageView === messageViewType.ReplyMessage && (
                    <>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Reply message
                        </Typography>

                        <div className="messageTopBarContainer">
                            <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '20px' }}>
                                <Typography id="modal-modal-description" sx={{ mt: '4px' }}>
                                    <b>Subject:</b>
                                </Typography>
                                <Input defaultValue={subjectValue} onChange={(e) => setSubjectValue(e.target.value)}/>
                            </div>
                        </div>

                        <div className="messageTextReplyContainer">
                            <textarea onChange={(e) => setTextValue(e.target.value)} className="messageTextArea"></textarea>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '20px', paddingTop: '20px' }}>
                            <Button variant="contained" onClick={handleSentMessage}>{t('SentMessage')}</Button>
                        </div>
                    </>
                )}

                {(props.messageObject && messageView === messageViewType.ViewMessage) && (
                    <>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Message
                        </Typography>

                        <div className="messageTopBarContainer">
                            <div>
                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    <b>Subject:</b> {props.messageObject.messageSubject}
                                </Typography>
                                <Typography id="modal-modal-description" sx={{ mt: 0 }}>
                                    <b>Sent:</b>  {moment(props.messageObject.createdAt).format("DD-MM-YYYY HH:mm")}
                                </Typography>
                            </div>
                            {/*
                            <div>
                                <Button variant="contained" onClick={handleReplyMessage}>{t('ReplyMessage')}</Button>
                            </div>
                            */}
                        </div>
                        <div className="messageTextContainer">
                            <Typography id="modal-modal-description" sx={{ mt: 0 }} >
                                <div dangerouslySetInnerHTML={{ __html: props.messageObject.messageText }}></div>
                            </Typography>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '20px', paddingTop: '20px' }}>
                            <Button variant="contained" onClick={props.onClose}>{t('CloseMessage')}</Button>
                        </div>
                    </>
                )}
            </Box>
        </Modal>
    );
}

export default MessageModal;