import React from "react";
import { jwtDecode } from "jwt-decode";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoutes = () => {
  // Validata Token
  var isExpired = true;
  const token = localStorage.getItem("id_token");
  if (token && token !== "") {
    var decodedToken = jwtDecode(token);

    var dateNow = new Date();
    if (decodedToken.exp) {
      if (decodedToken.exp * 1000 < dateNow.getTime()) {
        console.log("Token expired.");
        localStorage.removeItem("id_token");
      } else {
        console.log("Valid token");
        isExpired = false;
      }
    }
  }

  return isExpired ? <Navigate to="/login" replace /> : <Outlet />;
};

export default ProtectedRoutes;
