import React, { useRef, useState } from "react";
import "./changePassword.css";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useMutation, gql } from "@apollo/client";
import { Navigate, useParams } from "react-router-dom";

interface IProps {}

const CHANGE_PASSWORD_GQL = gql`
  mutation setNewPassword($customerId: String!, $password: String!) {
    setNewPassword(customerId: $customerId, password: $password)
  }
`;

const ChangePassword: React.FC<IProps> = () => {
  const [showError, setShowError] = useState(false);
  const password1TextBox = useRef<HTMLInputElement | null>(null);
  const password2TextBox = useRef<HTMLInputElement | null>(null);
  const [ChangePassword, { data }] = useMutation(CHANGE_PASSWORD_GQL);

  let { customerId, token } = useParams();

  const handleChangePasswordClicked = () => {
    const pw1 = password1TextBox.current?.value;
    const pw2 = password2TextBox.current?.value;

    if (pw1 === pw2 && pw1 !== "") {
      ChangePassword({
        errorPolicy: "all",
        variables: {
          password: pw1,
          customerId: customerId,
        },
        context: {
          headers: {
            authorization: "Bearer " + token,
          },
        },
      });
    } else {
      setShowError(true);
    }
  };

  //console.log('data:', data)

  if (data && data.setNewPassword) {
    //localStorage.setItem("id_token", data.login);
    return <Navigate to="/login" replace />;
  } else {
    return (
      <div className="changePassword">
        <div className="logo"></div>
        <div className="background"></div>
        <div className="container">
          <div className="boxContainer">
            <div className="textContainer">
              <h1>Skift adgangskode</h1>
              {showError && <p className="warning">De indtastede adgangskoder matcher ikke - prøv igen.</p>}
              <TextField
                fullWidth
                id="password1"
                label="Ny adgangskode"
                variant="standard"
                defaultValue={""}
                type="password"
                inputRef={password1TextBox}
              />
              <br />
              <br />
              <TextField
                fullWidth
                id="password2"
                label="Bekræft adgangskode"
                variant="standard"
                defaultValue={""}
                type="password"
                inputRef={password2TextBox}
              />
              <br />
            </div>
            <div className="buttonContainer">
              <Button
                onClick={() => {
                  handleChangePasswordClicked();
                }}
                className="button"
                variant="contained"
              >
                Skift adgangskode
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
export default ChangePassword;
