import "./error.css";
import { datastore } from "./../../datastore"; 
interface IProps {
  errorCode: number;
}

const Error: React.FC<IProps> = (props) => {
  console.log("Error code:", props.errorCode);
  console.log("datastore:", datastore);

  localStorage.clear(); 
  datastore.data.shopNo = ""
  datastore.data.providerCustomerName = ""
  datastore.data.customerId = ""

  setTimeout(() => {
    window.location.href = "/";
  }, 5000);

  return (
    <>
      <div className="error">
        <div className="logo"></div>
        <div className="background"></div>
        <div className="centerVertical errorContainer">
          <div className="errorMessage">

            {/* 250 - Page not found */}
            {props.errorCode === 250 && <div>Vi kunne ikke finde dig i systemmet, kontakt din fotograf.</div>}


            {/* 404 - Page not found */}
            {props.errorCode === 404 && <div>Siden kunne ikke findes</div>}
            {/* 405 - Error getting customer */}
            {props.errorCode === 405 && <div>Der er opstået en fejl</div>}
            {/* 406 - Error decoding State */}
            {props.errorCode === 406 && <div>Der er opstået en fejl</div>}

            {/* 500 - Error connecting to server */}
            {props.errorCode === 500 && <div>Der er opstået en fejl, prøv igen om lidt.</div>}
            {/* 501 - Error getting data from server */}
            {props.errorCode === 501 && <div>Der er opstået en fejl, prøv igen om lidt.</div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default Error;
