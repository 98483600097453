
import React, { useState, useEffect, RefObject } from 'react';

interface CharCounterProps {
  textFieldRef: RefObject<HTMLTextAreaElement | HTMLInputElement>;
  maxChars?: number;
}

const CharCounter: React.FC<CharCounterProps> = ({ textFieldRef, maxChars = 100 }) => {
  const [charCount, setCharCount] = useState<number>(0);

  useEffect(() => {
    const updateCharCount = () => {
      setCharCount(textFieldRef.current?.value.length || 0);
    };

    // Initialize the character count
    updateCharCount();

    // Add an event listener to update char count on input
    textFieldRef.current?.addEventListener('input', updateCharCount);


    // Cleanup event listener on component unmount
    return () => {
      textFieldRef.current?.removeEventListener('input', updateCharCount);
    };

    
  }, [textFieldRef]);

  const progressPercentage = (charCount / maxChars) * 100;

  return (
    <div style={{marginBottom: '10px', width: '100px', textAlign:'right'}}>
      <p style={{fontSize:'10px'}}>{charCount}/{maxChars}</p>
      <div style={{
        position: 'relative',
        height: '8px',
        width: '100%',
        backgroundColor: '#e0e0e0',
        borderRadius: '4px',
        overflow: 'hidden',
        marginTop: '4px',
      }}>
        <div style={{
          height: '100%',
          width: `${progressPercentage}%`,
          backgroundColor: progressPercentage >= 100 ? 'red' : '#76c7c0',
          transition: 'width 0.2s ease',
        }} />
      </div>
    </div>
  );
};

export default CharCounter;
