import React, { useRef, useEffect } from "react";
import Error from "../error/error";
import { FaRegPlayCircle, FaReply } from "react-icons/fa";
import Grid from "@mui/material/Unstable_Grid2";
import "./autherView.css";
import { useQuery, gql, useMutation } from "@apollo/client";
import Paper from '@mui/material/Paper';
import { useParams } from "react-router-dom";
import TopMenu, { TopMenuItem, viewType } from "../../components/TopMenu/TopMenu";
import Logo from "../../components/Logo/Logo";
import { Card, CardContent, CardHeader, Snackbar, Input, TextField, Button, styled } from "@mui/material";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { useTranslation } from 'react-i18next';

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import Counter from "yet-another-react-lightbox/plugins/counter";
import "yet-another-react-lightbox/plugins/counter.css";

import Video from "yet-another-react-lightbox/plugins/video";
import "yet-another-react-lightbox/styles.css";
import moment from "moment";
import { datastore } from "../../datastore";
import MessageModal, { messageViewType } from "../../components/MessageModal/MessageModal";
import CharCounter from "../../components/charCounter";

const SET_MESSAGE_AS_READ = gql`
  mutation setMessageAsRead($id: ID!) {
    setMessageAsRead(id: $id) {
      id
    }
  }
`;

const UPDATE_SUBCONTRACTOR_ORDER = gql`
  mutation updateSubcontractorOrder($input: UpdateSubcontractorOrderInput!) {
    updateSubcontractorOrder(input: $input) {
      id
    }
  }
`;

const UPDATE_ORDER = gql`
  mutation updateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      id
    }
  }
`;

const GET_ORDER = gql`
  query subcontractorOrder($id: ID!) {
    subcontractorOrder(id: $id) {
      id
      status
      subcontractor {
        id
        language
      }
      messages {
        id
        messageSubject
        messageText
        messageSender
        threadId
        photographyCompany {
          id
          name
        }
        dateRead
        replyMessage { 
          id
        }
        createdAt
      }
      photographyCompany {
        id
        name
        address
        city
        zipCode
        cvr
        email
        logoUrl
        phoneNo
        primaryColor
        secondaryColor
        textColor
        buyingTermsUrl
        showAllowOrderConfirmedNotificationButton
        showAllowOrderMessageNotificationButton
        showAllowOrderDeliveredNotificationButton
      }
      order {
        id
        caseNo
          estateAddress
          estateZipCode
          estateCity
          estateType
          estateFloorCount
          estateRoomCount
          estateLivingArea
          estateBusinessArea
          sellerName
          sellerPhone
          sellerEmail
          sellerType
          textHeading
          textSmall
          textMedium
          textLarge
          textSoMe
          textWebsite
          texts {
            id
            name
            sorting
            text
            section {
              name
              textType
              textLength
            }
          }
          customer {
            name
          }
          photeshotEvent {
            id
            start
            end
          }
          media {
            id
            thumpnailUrl,
            orgUrl,
            heightInPx,
            widthInPx,
            mimeType,
            description
          }
      }

      createdAt
      updatedAt
    }
  }
`;

const UPDATE_TEXT_GQL = gql`
  mutation updateText($input: UpdateTextInput!) {
    updateText(input: $input) {
      id
    }
  }
`;

const AutherView: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { subcontractorOrderId, token } = useParams();
  const [openSavedInfo, setOpenSavedInfo] = React.useState(false);
  const [openErrorInfo, setOpenErrorInfo] = React.useState(false);
  const [openImageViewer, setOpenImageViewer] = React.useState(false);
  const [SelectedImage, setSelectedImage] = React.useState(0);
  const [order, setOrder] = React.useState<any>(null);
  const [openMessageModal, setOpenMessageModal] = React.useState(false);
  const [messageObj, setMessageObj] = React.useState(null);
  const [updateState, setUpdateState] = React.useState(0);


  const [updateSubcontractorOrder] = useMutation(UPDATE_SUBCONTRACTOR_ORDER);
  const [updateTextOnServer] = useMutation(UPDATE_TEXT_GQL);
  const [updateOrder] = useMutation(UPDATE_ORDER);
  const thumbnailsRef = React.useRef(null);
  const textFieldRefs = useRef<(HTMLTextAreaElement | null)[]>([]);

  localStorage.clear();
  datastore.data.token = token + '';


  //hack to init text counters
  useEffect(() => {
    setTimeout(() => {
      if (!updateState) {
        setUpdateState(updateState + 1);
      }
    }, 1000);
  }, []);

  const { loading, error, data, refetch } = useQuery(GET_ORDER, {
    variables: {
      id: subcontractorOrderId
    },
    context: {
      headers: {
        authorization: "Bearer " + token,
      },
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true

  });

  const [updateMessageAsRead] = useMutation(SET_MESSAGE_AS_READ, {
    context: {
      headers: {
        authorization: "Bearer " + token,
      }
    },
    refetchQueries: [{
      query: GET_ORDER, variables: { id: subcontractorOrderId }, context: {
        headers: {
          authorization: "Bearer " + token,
        }
      },
    }]
  });
  React.useEffect(() => {
    let root = document.documentElement;
    root.style.setProperty("--text-color", "#FFF");
    root.style.setProperty("--primary-color", "#000");
    root.style.setProperty("--secondary-color", "#FFF !important");

  }, []);

  React.useEffect(() => {
    if (data && data.subcontractorOrder && data.subcontractorOrder.subcontractor) {
      i18n.changeLanguage(data.subcontractorOrder.subcontractor.language);
    }
  }, [data]);

  if (loading) {
    return <div></div>;
  }

  if (error) {
    return <Error errorCode={500} />;
  }

  if (!data) {
    return (<Error errorCode={500} />);
  }

  //set colors
  //let root = document.documentElement;
  //root.style.setProperty("--text-color", data.subcontractorOrder.photographyCompany.textColor);
  //root.style.setProperty("--primary-color", data.subcontractorOrder.photographyCompany.primaryColor);
  //root.style.setProperty("--secondary-color", data.subcontractorOrder.photographyCompany.secondaryColor);
  //root.style.setProperty("--logoUrl", "url(" + data.subcontractorOrder.photographyCompany.logoUrl + ")");

  const markAsDone = () => {
    let inputData = {} as any;
    inputData['status'] = 'COMPLETED';
    inputData['id'] = data.subcontractorOrder.id;

    updateSubcontractorOrder({
      variables: { input: inputData }, context: {
        headers: {
          authorization: "Bearer " + token,
        },
      },
    }).then(() => {
      refetch();
      setOpenSavedInfo(true)
    }).catch((error) => {
      console.log('Error updating order:', error);
      setOpenErrorInfo(true);
    });
  }

  const updateText = (field: string, value: string) => {
    const fieldId = field.split('_')[2];

    //find the text in the order and update it
    let tmp = { ...order };
    let tmpTexts = tmp.texts.map((text: any) =>
      text.id === fieldId ? { ...text, text: value } : text
    );
    tmp.texts = tmpTexts;
    setOrder(tmp);

    updateTextOnServer({
      variables: {
        input: { id: fieldId, text: value }
      }, context: {
        headers: {
          authorization: "Bearer " + token,
        },
      }

    })
      .then(() => {
        setOpenSavedInfo(true)
      })
      .catch(() => {
        console.log('Error updating order:', error);
        setOpenErrorInfo(true);
      });
  }

  const handleDataChange = (event: any) => {
    let inputData = {} as any;

    inputData[event.target.name] = event.target.value;
    inputData['id'] = data.subcontractorOrder.order.id;

    let tmp = { ...order }
    tmp[event.target.name] = event.target.value;
    setOrder(tmp);

    updateOrder({
      variables: { input: inputData }, context: {
        headers: {
          authorization: "Bearer " + token,
        },
      },
    }).then(() => {


      setOpenSavedInfo(true)

    }).catch((error) => {
      console.log('Error updating order:', error);
      setOpenErrorInfo(true);
    });

  }


  const handleInfoClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSavedInfo(false);
  };

  const handleErrorClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenErrorInfo(false);
  };


  //map media to slides
  const slides = data.subcontractorOrder.order.media.map((media: any) => {
    if (media.mimeType === "video/mp4") {
      return { poster: media.thumpnailUrl, width: media.widthInPx, height: media.heightInPx, type: "video", sources: [{ src: media.orgUrl, type: "video/mp4" }] };
    } else {
      return { src: media.orgUrl, width: media.widthInPx, height: media.heightInPx, SrcSet: [{ src: media.orgUrl }], type: "image" };
    }
  });

  const Textarea = styled(TextareaAutosize)(
    () => `
    box-sizing: border-box;
    width: 100%;
    font-family: Roboto, 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 3px 3px 0 3px;
    
  `,
  );

  if (order === null) {
    setOrder(data.subcontractorOrder.order as any);
    return (<></>);
  }

  const handleMessageItemClicked = (messageId: string) => () => {
    //find message
    let message = data.subcontractorOrder.messages.find((message: any) => message.id === messageId);
    if (!message) {
      return;
    }
    if (message.dateRead === null) {
      updateMessageAsRead({ variables: { id: messageId } });
    }

    setMessageObj(message);
    setOpenMessageModal(true);
  }


  return (
    <div className="orderInfo MainArea">
      <header className="TopAreaMain">
        <Logo />
        <TopMenu selectedItem={TopMenuItem.AuthorOrderList} view={viewType.Auther} customerName={null} />
      </header>
      <Grid container spacing={0}>
        <Grid xs={12}>

          <div className="contentContainer">
            <div className="Heading">{t('OrderInfoHeading')}</div>
            <Grid container spacing={2}>

              <Grid xs={6} spacing={2}>
                <Paper elevation={3} style={{ height: '100%' }}>
                  <Card style={{ height: '100%' }}>
                    <CardHeader title={t('OrderInfoAdressHeader')} />
                    <CardContent>
                      <div>
                        <div className="item">
                          <div className="itemLabel">{t('OrderCustomerName')}:</div>
                          <div className="itemData">{data.subcontractorOrder.order.customer.name}</div>
                        </div>
                        <div className="item">
                          <div className="itemLabel">{t('OrderItemNo')}:</div>
                          <div className="itemData">{data.subcontractorOrder.order.caseNo}</div>
                        </div>
                        <div className="item">
                          <div className="itemLabel">{t('PhotographyDate')}:</div>
                          {data.subcontractorOrder.order.photeshotEvent ? (
                            <div className="itemData">{moment(data.subcontractorOrder.order.photeshotEvent.start).format("DD-MM-YYYY")}  Kl.:{String(new Date(data.subcontractorOrder.order.photeshotEvent.start).getHours()).padStart(2, '0') + ":" + String(new Date(data.subcontractorOrder.order.photeshotEvent.start).getMinutes()).padStart(2, '0')} - {String(new Date(data.subcontractorOrder.order.photeshotEvent.end).getHours()).padStart(2, '0') + ":" + String(new Date(data.subcontractorOrder.order.photeshotEvent.end).getMinutes()).padStart(2, '0')}</div>
                          ) : (
                            <div className="itemData">Ikke planlagt</div>
                          )}
                        </div>
                        <div className="item">
                          <div className="itemLabel">{t('OrderInfoAdress')}:</div>
                          <div className="itemData">{data.subcontractorOrder.order.estateAddress}, {data.subcontractorOrder.order.estateZipCode} {data.subcontractorOrder.order.estateCity} </div>
                        </div>

                        <div className="item">
                          <div className="itemLabel">{t('OrderInfoEstateType')}:</div>
                          <div className="itemData">{data.subcontractorOrder.order.estateType}</div>
                        </div>

                        <div className="item">
                          <div className="itemLabel">{t('OrderInfoEstateFloorCount')}:</div>
                          <div className="itemData">{data.subcontractorOrder.order.estateFloorCount}</div>
                        </div>

                        <div className="item">
                          <div className="itemLabel">{t('OrderInfoEstateRoomCount')}:</div>
                          <div className="itemData">{data.subcontractorOrder.order.estateRoomCount}</div>
                        </div>

                        <div className="item">
                          <div className="itemLabel">{t('OrderInfoEstateLivingArea')}:</div>
                          <div className="itemData">{data.subcontractorOrder.order.estateLivingArea}</div>
                        </div>

                        <div className="item">
                          <div className="itemLabel">{t('OrderInfoEstateBusinessArea')}:</div>
                          <div className="itemData">{data.subcontractorOrder.order.estateBusinessArea}</div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>

              <Grid xs={6} spacing={2}>
                <Paper elevation={3} style={{ height: '100%' }}>
                  <Card style={{ height: '100%' }}>
                    <CardHeader title={t('MessagesHeader')} />
                    <CardContent style={{ padding: '0px' }}>
                      <div className="messageItemsHeading" key={'messageHeading'}>
                        <div className="messageSubject">{t('MessagesItemHeaderSubject')}</div>
                        <div className="messageDate">{t('MessagesItemHeaderRecived')}</div>
                        <div className="messageReplyIcon">&nbsp;</div>
                      </div>
                      {data.subcontractorOrder.messages.map((message: any) => {
                        if (message.dateRead === null) {
                          return (
                            <div className="messageItemUnread" key={message.id} onClick={handleMessageItemClicked(message.id)}>
                              <div className="messageSubject">{message.messageSubject}</div>
                              <div className="messageDate">{moment(message.createdAt).format("DD-MM-YYYY HH:mm")}</div>
                              {message.replyMessage ? (
                                <div className="messageReplyIcon"><FaReply /></div>
                              ) : (
                                <div className="messageReplyIcon"></div>
                              )}
                            </div>
                          )
                        } else {
                          return (
                            <div className="messageItem" key={message.id} onClick={handleMessageItemClicked(message.id)}>
                              <div className="messageSubject">{message.messageSubject}</div>
                              <div className="messageDate">{moment(message.createdAt).format("DD-MM-YYYY HH:mm")}</div>
                              {message.replyMessage ? (
                                <div className="messageReplyIcon"><FaReply /></div>
                              ) : (
                                <div className="messageReplyIcon"></div>
                              )}
                            </div>
                          )
                        }
                      })}
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>

              <Grid xs={6} style={{ height: '100%' }}>
                <Paper elevation={3} style={{ height: '100%' }}>
                  <Card style={{ height: '100%' }}>
                    <CardHeader title={t('OrderEstateContactHeading')} />
                    {["CANCELED"].includes(data.subcontractorOrder.status) ? (
                      <CardContent>
                        <div>
                          <div className="item">
                            <div className="itemLabel">{t('OrderEstateContactName')}:</div>
                            <div className="itemData"></div>
                          </div>

                          <div className="item">
                            <div className="itemLabel">{t('OrderEstateContactPhoneNo')}:</div>
                            <div className="itemData"></div>
                          </div>

                          <div className="item">
                            <div className="itemLabel">{t('OrderEstateContactEmail')}:</div>
                            <div className="itemData"></div>
                          </div>
                        </div>
                      </CardContent>
                    ) : (
                      <CardContent>
                        <div>
                          <div className="item">
                            <div className="itemLabel">{t('OrderEstateContactName')}:</div>
                            <div className="itemData">{data.subcontractorOrder.order.sellerName}</div>
                          </div>

                          <div className="item">
                            <div className="itemLabel">{t('OrderEstateContactPhoneNo')}:</div>
                            <div className="itemData">{data.subcontractorOrder.order.sellerPhone}</div>
                          </div>

                          <div className="item">
                            <div className="itemLabel">{t('OrderEstateContactEmail')}:</div>
                            <div className="itemData">{data.subcontractorOrder.order.sellerEmail}</div>
                          </div>
                        </div>
                      </CardContent>
                    )}
                  </Card>
                </Paper>
              </Grid>
              <Grid xs={6} style={{ height: '223px' }} >
                <Paper elevation={3} style={{ height: '100%' }}>
                  <Card style={{ height: '100%' }}>
                    <CardHeader title={t('OrderStatusHeading')} />
                    <CardContent>
                      {["REJECTED", "CANCELED"].includes(data.subcontractorOrder.status) ? (
                        <div>
                          <div className="item">
                            {t('OrderStatusCanceled')}
                          </div>
                        </div>
                      ) : (
                        <div>
                          {data.subcontractorOrder.status === 'COMPLETED' ? (
                            <div className="item">
                              {t('OrderStatusDelivered')}
                            </div>
                          ) : (
                            <div className="item">
                              <Button variant="contained" className="" fullWidth onClick={() => markAsDone()}>{t('OrderRawMaterialClickToComplete')}</Button>
                            </div>
                          )}
                        </div>
                      )}
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>

              {!["CANCELED"].includes(data.subcontractorOrder.status) && (
                <>
                  {/* Images and videos */}
                  {data.subcontractorOrder.order.media.length > 0 && (
                    <Grid xs={12}>
                      <Paper elevation={3} >
                        <Card>
                          <CardHeader title={t('OrderMediaHeading')} />
                          <CardContent>
                            <div>
                              <div className="mediaContainer">
                                {data.subcontractorOrder.order.media.map((media: any, index: number) => {
                                  return (
                                    <div key={media.id} className="mediaItem" onClick={() => { setSelectedImage(index); setOpenImageViewer(true) }}>
                                      <img src={media.thumpnailUrl} alt="" />
                                      {media.mimeType === "video/mp4" && <div className="playIcon">
                                        <div className="orderMediaListVideoPlayIconContainer">
                                          <FaRegPlayCircle className="orderMediaListVideoPlayIcon" />
                                        </div>
                                      </div>}

                                      <div className="mediaDescription">{media.description}</div>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      </Paper>
                    </Grid>
                  )}

                  {/* TEXTS */}
                  <Grid xs={12}>
                    <Paper elevation={3} >
                      <Card>
                        <CardHeader title={t('OrderMediaTextHeading')} subheader={t('OrderMediaTextSubHeading')} />
                        <CardContent>
                          {order.texts.length > 0 ? (
                            <>
                              {order.texts.map((text: any, index: number) => {
                                // Initialize the ref for each text item
                                if (!textFieldRefs.current[index]) {
                                  textFieldRefs.current[index] = null;
                                }
                                return (
                                  <div className="item">
                                    <div className="itemData" style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div className="itemLabel">{text.section.name} ({text.section.textLength} tegn):{" "}</div>
                                        <CharCounter textFieldRef={{ current: textFieldRefs.current[index] }} maxChars={text.section.textLength} />
                                      </div>
                                      <textarea className="textAreaText"
                                        ref={(el: any) => (textFieldRefs.current[index] = el)} name={'text_id_' + text.id} defaultValue={text.text} onBlur={(e) => updateText('text_id_' + text.id, e.target.value)} />
                                    </div>
                                  </div>
                                )
                              })}
                            </>
                          ) : (
                            <>
                              <div className="item">
                                <div className="itemLabel">{t('OrderMediaInputHeading')}:</div>
                                <div className="itemData" style={{ width: '100%' }}>
                                  <Textarea defaultValue={order.textHeading} name="textHeading" onBlur={(e) => { handleDataChange(e) }} />
                                </div>
                              </div>
                              <div className="item">
                                <div className="itemLabel">{t('OrderMediaInputTextSmall')}:</div>
                                <div className="itemData" style={{ width: '100%' }}>
                                  <Textarea defaultValue={order.textSmall} name="textSmall" onBlur={(e) => { handleDataChange(e) }} />
                                </div>
                              </div>
                              <div className="item">
                                <div className="itemLabel">{t('OrderMediaInputTextMedium')}:</div>
                                <div className="itemData" style={{ width: '100%' }}>
                                  <Textarea defaultValue={order.textMedium} name="textMedium" onBlur={(e) => { handleDataChange(e) }} />
                                </div>
                              </div>
                              <div className="item">
                                <div className="itemLabel">{t('OrderMediaInputTextLarge')}:</div>
                                <div className="itemData" style={{ width: '100%' }}>
                                  <Textarea defaultValue={order.textLarge} name="textLarge" onBlur={(e) => { handleDataChange(e) }} />
                                </div>
                              </div>
                              <div className="item">
                                <div className="itemLabel">{t('OrderMediaInputTextSoMe')}:</div>
                                <div className="itemData" style={{ width: '100%' }}>
                                  <Textarea defaultValue={order.textSoMe} name="textSoMe" onBlur={(e) => { handleDataChange(e) }} />
                                </div>
                              </div>
                              <div className="item">
                                <div className="itemLabel">{t('OrderMediaInputTextWebsite')}:</div>
                                <div className="itemData" style={{ width: '100%' }}>
                                  <Textarea defaultValue={order.textWebsite} name="textWebsite" onBlur={(e) => { handleDataChange(e) }} />
                                </div>
                              </div>
                            </>
                          )}

                        </CardContent>
                      </Card>
                    </Paper>
                  </Grid>

                </>)}

            </Grid>
          </div>
        </Grid>
      </Grid>

      <Snackbar
        open={openSavedInfo}
        autoHideDuration={2000}
        message={t('OrderChangeSaved')}
        onClose={handleInfoClose}
      />

      <Snackbar
        open={openErrorInfo}
        autoHideDuration={2000}
        ContentProps={{
          sx: {
            background: "red"
          }
        }}
        message={t('OrderChangeSaveFailed')}
        onClose={handleErrorClose}
      />

      <Lightbox
        styles={{ thumbnailsContainer: { backgroundColor: "#353535" } }}
        plugins={[Thumbnails, Counter, Video]}
        counter={{ container: { style: { top: "unset", bottom: 0 } } }}
        thumbnails={{ ref: thumbnailsRef }}
        open={openImageViewer}
        close={() => setOpenImageViewer(false)}
        slides={slides}
        index={SelectedImage}
      />

      <MessageModal photographyCompanyId={data.subcontractorOrder.photographyCompany.id} open={openMessageModal} onClose={() => setOpenMessageModal(false)} view={messageViewType.ViewMessage} messageObject={messageObj} />

    </div>
  );
};

export default AutherView;
