//TODO, move url parameter to this file.
//http://localhost:3000/neworder?provider=Mindworking&customer=estate&maeglersMail=breyen@home.dk&sagsnr=002P00003&adresse=Ove%20Gjeddes%20Gade%203,%203.%20tv.&postnr=8200&by=%C3%85rhus%20N&stednavn=%C3%85rhus%20N&ejdtype=Ejerlejlighed&areal=0&antalPlan=1&antalVaerelser=2&saelger1Navn=Kirsten%20Mindworking&saelger1TlfPriv=56565656&saelger1TlfArb&saelger1TlfMob&saelger1Mail=kmi@mindworking.dk&saelger1Type=Privat&kaelderAreal=0&carportAreal=0&garageAreal=0&udehusAreal=0&erhvervsAreal=0&boligareal=67&shopNo=N270109&caseNo=002P00003

//13617
export default class Mindworking {
  getCaseInfo = async (SystemUrl: string | null, ShopNo: string | null, Caseno: string) => {
    const url =
      SystemUrl + "/resources/shops/" +
      ShopNo +
      "/cases/" +
      Caseno +
      "/data/casedata.json?username=info@magnalux.dk&password=Hyblik78&rnd=" +
      Math.random();

    try {
      return await fetch(url).then((response) => response.json());
    } catch {
      return null;
    }
  };

  getCases = async (SystemUrl: string | null, ShopNo: string | null) => {
    const url =
      SystemUrl + "/resources/shops/" +
      ShopNo +
      "/cases/cases.json?username=info@magnalux.dk&password=Hyblik78&rnd=" +
      Math.random();

    try {
      return await fetch(url).then((response) => response.json());
    } catch {
      return null;
    }
  };

  getCaseNosFromXML = async (SystemUrl: string | null, ShopNo: string | null) => {
    const url =
      SystemUrl + "/resources/shops/" +
      ShopNo +
      "/cases/cases.xml?username=info@magnalux.dk&password=Hyblik78&rnd=" +
      Math.random();

    try {
      const xmlReaponse = await fetch(url).then((response) => response.text());

      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlReaponse, "text/xml");

      const caseSummary = xmlDoc.getElementsByTagName("CaseSummary");
      const caseNoAry = [];
      for (let i = 0; i < caseSummary.length; i++) {
        const status = caseSummary[i].getElementsByTagName("Status")[0].innerHTML;
        if (status !== "Udgået" && status !== "Solgt") {
          caseNoAry.push(caseSummary[i].getElementsByTagName("CaseNo")[0].innerHTML);
        }
      }

      return caseNoAry;

    } catch {
      return null;
    }
  };
}
